import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
// import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useForm } from '../../hooks/useForm';
import { login } from '../../actions/auth';
// import imgLogo from '../../assets/img/logo.jpg';
import imgLogo from '../../assets/img/logo.png';
import imgGV from '../../assets/img/logo.png';
import { useStyles } from './StylesLogin';
import { setError } from '../../actions/ui';
import { FormHelperText } from '@material-ui/core';
import { ShowError } from '../../components/ShowError/ShowError';

const Copyright = () => {
  const classes = useStyles();
  return (
    <Grid 
      container
      spacing={1}
      alignItems="flex-end"
      alignContent="center"
      >
      <Grid 
        container 
        item xs={4}
        direction='column'
        alignItems="center"
        alignContent="center"
      >        
        <Typography variant='caption' color='textSecondary' > Powered by </Typography>
        <img className={ classes.logoGV } src={ imgGV } alt="GV"/>
      </Grid>
      <Grid item xs={8}>        
        <Typography variant="caption" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="#">
              Systemaz
          </Link>
          {' '}{new Date().getFullYear()}{'.'}
        </Typography>
      </Grid>
    </Grid>
  );
};


export const LoginPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { msgError, loading } = useSelector(state => state.ui);
    
    const openError = Boolean( msgError );
    
    const showError = ( msg, tipo ) => {
      ShowError(tipo, msg);
      dispatch( setError(null) );
    }

    const [ values, onInputChange ] = useForm({
        usuario: '',
        clave: '',
    });

    const { usuario, clave } = values;
    const [showPassword, setShowPassword] = React.useState( false );
    const [fieldsErr, setFieldsErr] = React.useState({
      usuarioErr: false,
      claveErr: false    
    })

    const handleClickShowPassword = () => {
        setShowPassword( !showPassword );
    };

    const handleValidaLogin = (e) => {
        e.preventDefault();
        setFieldsErr({
          ...fieldsErr,
          usuarioErr: usuario.length === 0 ? true : false,
          claveErr: clave.length === 0 ? true : false,
        })
        
        if (usuario.length > 0 && clave.length > 0 ) {
          dispatch( login( usuario, clave ) );
        }
    };
    
    return (
    <Grid container component="main" className={classes.root}>
      {/* <CssBaseline /> */}
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          
          <img className={ classes.logo } src={ imgLogo } alt="Logo"/>

          <div>
            <Typography component="h1" variant="h6">
              Inicio de sesión
            </Typography>
          </div>

          <form onSubmit={ handleValidaLogin } className={classes.form} noValidate >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="usuario"
              label="Usuario"
              name="usuario"              
              value={ usuario }
              autoComplete="email"
              autoFocus
              error={fieldsErr.usuarioErr}
              onChange={ onInputChange }
            />
            <FormHelperText error className={classes.errortext}>
              { fieldsErr.usuarioErr ? 'Se requiere usuario' : <span>&nbsp;</span> }
            </FormHelperText>


            <FormControl 
                variant="outlined"
                fullWidth
                required
                margin="normal"
                error={fieldsErr.claveErr}
                >
                <InputLabel htmlFor="password">Contraseña</InputLabel>
                <OutlinedInput
                    id="password"
                    type={ showPassword ? 'text' : 'password'}
                    name='clave'
                    value={ clave }
                    autoComplete="current-password"
                    onChange={ onInputChange }                    
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={ handleClickShowPassword }
                        // onMouseDown={ handleMouseDownPassword }
                        edge="end"
                        >
                        { showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                    }
                    labelWidth={90}
                />
                </FormControl>
                <FormHelperText error className={classes.errortext}>
                  { fieldsErr.claveErr ? 'Se requiere contraseña' : <span>&nbsp;</span> }
                </FormHelperText>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
            >
              Iniciar sesión
              {loading && <CircularProgress size={48} className={classes.buttonProgress} />}
            </Button>

            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                    {'¿Olvidaste tu contraseña?'}
                </Link>
              </Grid>

              {/* <Grid item>
                <Link href="#" variant="body2">
                    {"¿No tienes una cuenta? Regístrate"}
                </Link>
              </Grid> */}

            </Grid>

            <Box mt={8}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>

      { openError && showError( 'ERROR: ' + msgError, 'error') }

    </Grid>
  );
}

