import React from 'react';
import { TextField } from '@material-ui/core';

const tipo = 'outlined';
const margen = 'dense';

export const TextEdit = ( props ) => {
    return (
        <TextField variant={tipo} margin={margen} fullWidth { ...props } />
    )
}
