import React, { useEffect, useState } from 'react';
import { 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    Grid,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { format } from 'date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useForm } from '../../hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { setError } from '../../actions/ui';
import { ShowError } from '../../components/ShowError/ShowError';
import { TextEdit } from '../../components/TextEdit/TextEdit';
import { saveBanco, updateBanco } from '../../actions/acBancos';
import { TextDate } from '../../components/TextDate/TextDate';
import { BotonCancel, BotonOK } from '../../Styles/Styles';
import { countryToFlag, monedas } from '../../Utils/Util';

function PaperComponent(props) {
    return (
      <Draggable handle="#Cap-Bancos" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}

export const CapBan = ({ open, onClose, registro, alta, setRegistro }) => {

    const dispatch = useDispatch();
    // const [open, setOpen] = useState(true);
    const [ values, onInputChange, reset ] = useForm( registro )
    const [wDate, setwDate] = useState(new Date());

    const [fieldsErr, setFieldsErr] = React.useState({
        ValidaDatos: false,
        ctaErr: false,
        fechaErr: false,
    });

    let titulo;
    alta ? titulo = 'Alta de Cuentas Bancarias' : titulo = 'Cambio de Cuentas Bancarias';

    const  {
        cta,
        banco,
        titular,
        sucursal,
        ejecutivo,
        direccion,
        telefono,
    } = values;

    const { msgError } = useSelector(state => state.ui);

    const acGrabar = async (e) => {
        e.preventDefault();
        setFieldsErr({
            ...fieldsErr,
            ctaErr: cta.length === 0 ? true : false,
        });

        if ( cta.length === 0 ) {
            dispatch( setError('ERROR: Se debe de registrar una cuenta') );
            return;
        }

        const manda = {
            ...values,
            alta: format(wDate, 'yyyyMMdd'),
        }

        let resp;
        if (alta) {
            resp = await saveBanco( manda );
        } else {
            resp = await updateBanco( manda );
        }

        if (resp.coderesult !== 200) {
            dispatch( setError( resp.Message ));
        } else {
            setRegistro({ ...manda })
            reset();
            onClose();            
        }
    }
    
    useEffect(() => {
        
    }, [msgError])
    
    const showError = ( msg, tipo ) => {
        ShowError('error', msg);
        dispatch( setError(null) );
    }

    return (
        <>
            <Dialog open={open} maxWidth='md' fullWidth={true} PaperComponent={ PaperComponent }>
                <DialogTitle  id="Cap-Bancos"> { titulo } </DialogTitle>
                <DialogContent dividers={ true }>
                    <DialogContentText id="scroll-dialog-description" >

                        { msgError && showError( msgError, 'error') }
                        <form autoComplete='off'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <TextEdit required autoFocus name='cta' value={cta} label='Cuenta'  onChange={onInputChange} error={ fieldsErr.ctaErr } />
                                </Grid>    
                                <Grid item xs={12} md={3}>
                                    <TextDate required label='Apertura' value={wDate} onChange={ date => setwDate( date ) } />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextEdit name='titular' value={titular} label='Nombre' onChange={ onInputChange }/>
                                </Grid>    
                                <Grid item xs={12} md={4}>
                                    <TextEdit name='banco' value={banco} label='Banco' onChange={ onInputChange }/>
                                </Grid>    
                                <Grid item xs={12} md={8}>
                                    <TextEdit name='sucursal' value={sucursal} label='Sucursal' onChange={ onInputChange }/>
                                </Grid>    
                                <Grid item xs={12} md={12}>
                                    <TextEdit name='ejecutivo' value={ejecutivo} label='Ejecutivo'  onChange={ onInputChange }/>
                                </Grid>    
                                <Grid item xs={12} md={12}>
                                    <TextEdit name='direccion' value={direccion} label='Direccion'  onChange={ onInputChange }/>
                                </Grid>    
                                <Grid item xs={12} md={4}>
                                    <TextEdit name='telefono' value={telefono} label='Telefono'  onChange={ onInputChange }/>
                                </Grid>    
                                <Grid item xs={12} md={4}>

                                <Autocomplete
                                    id="select-moneda"
                                    style={{ width: 300 }}
                                    options={monedas}
                                    // classes={{
                                    //     option: classes.option,
                                    // }}
                                    autoHighlight
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                        <span>{countryToFlag(option.code)}</span>
                                        {option.label} ({option.code}) 
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextEdit
                                        {...params}
                                        label="Moneda"
                                        variant="outlined"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                        />
                                    )}
                                    />
                                </Grid>    
                            </Grid>
                        </form>

                    </DialogContentText>
                </DialogContent>
                
                <DialogActions>
                    <BotonOK type="submit" variant='outlined' onClick={ acGrabar } >
                        Grabar
                    </BotonOK>
                    <BotonCancel variant='outlined' onClick={ onClose } >
                        Cancelar
                    </BotonCancel>
                </DialogActions>


            </Dialog>
        </>
    )
}
