import { makeStyles } from '@material-ui/core/styles';
import imgFondo from '../../assets/img/fondo/login.jpg'

export const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',      
    },
    image: {
      backgroundImage: `url(${ imgFondo })`,
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: theme.spacing(6, 6),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1, 0, 4),
      backgroundColor: theme.palette.secondary.main,
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
    large:{
        width: theme.spacing(6),
        height: theme.spacing(6),
        margin: theme.spacing(3, 0),
    },
    logo: {
      // maxWidth: '220px',
      marginBottom: theme.spacing(3),
      borderRadius: '90px',
      // width: '160px',
      height: '160px',
    },
    logoGV: {
      maxWidth: '48px',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      maxWidth: '400px',
    },
    buttonProgress: {
      // color: deepOrange[900],
      color: theme.palette.secondary.main,
      position: 'absolute',
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      textTransform: 'none',
      // background: 'linear-gradient(#5f8ab9,#3e648d 50%,#385a7f)',
      background: theme.palette.titulo.background,
      color: theme.palette.titulo.color,
    },
}));
