import { types } from "../types/types";
const inicial = {
    isLogin: false,
    uid: '',
    name: ''
};

export const authReducer = ( state=inicial, action ) => {
    switch ( action.type ) {
        case types.login:
            return {
                isLogin: true,
                uid: action.payload.uid,
                name: action.payload.name
            }
            
        case types.logout: 
            return {
                // ...state,
                isLogin: false
            }
    
        default:
            return state;
    }
}