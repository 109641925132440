import React, { useEffect, useState } from 'react'
import { 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    Grid,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { format } from 'date-fns';

import { useForm } from '../../hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { setError } from '../../actions/ui';
import { ShowError } from '../../components/ShowError/ShowError';
import { TextEdit } from '../../components/TextEdit/TextEdit';
import { saveTraspaso } from '../../actions/acBancos';
import { LookupBanco } from '../../components/LookupBanco/LookupBanco';
import { TextDate } from '../../components/TextDate/TextDate';
import { BotonCancel, BotonOK } from '../../Styles/Styles';

function PaperComponent(props) {
    return (
      <Draggable handle="#Cap-Traspasos-Bancarios" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}

export const Traspasos = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const [wDate, setwDate] = useState(new Date());
    const [origen, setOrigen] = useState({
        idbanco: -1,
        cta: '',
        titular: '',
        banco: ''
    });
    const [destino, setDestino] = useState({
        idbanco: -1,
        cta: '',
        titular: '',
        banco: ''
    });

    const [fieldsErr, setFieldsErr] = React.useState({
        ValidaDatos: false,
        origenErr: false,
        destinoErr: false,
        fechaErr: false,
        Importe: false,
    });

    const [ values, onInputChange, reset ] = useForm({
        refer: '',
        concepto: '',
        importe: 0,
        status:  'TR',
    });

    const  {
        importe,
    } = values;

    const { msgError } = useSelector(state => state.ui);

    const onClose = () => {
        setOpen(false)
    }

    const acGrabar = async (e) => {
        e.preventDefault();
        setFieldsErr({
            ...fieldsErr,
            origenErr: origen.cta?.length === 0 ? true : false,
            destinoErr: destino.cta?.length === 0 ? true : false,
            importe: (importe <= 0 ) ? true : false,
        });

        if ( origen.cta?.length === 0 ) {
            dispatch( setError('ERROR: Se debe de registrar una cuenta origen') );
            return;
        }
        if ( destino.cta?.length === 0 ) {
            dispatch( setError('ERROR: Se debe de registrar una cuenta destino') );
            return;
        }
        if ( origen.cta === destino.cta ) {
            dispatch( setError('ERROR: No puden ser las mismas cuentas') );
            return;
        }

        if ( importe <= 0  ) {
            dispatch( setError('ERROR: Importe no valido') );
            return;
        }
        if ( !wDate ) {
            return
        }
        
        const manda = {
            ...values,
            idorigen: origen.idbanco,
            iddestino: destino.idbanco,
            origen: origen.cta,
            destino: destino.cta,
            fecha: format(wDate, 'yyyyMMdd'),
        }

        const resp = await saveTraspaso( manda );
        if (resp.coderesult !== 200) {
            dispatch( setError( resp.Message ));
        } else {
            reset();
            onClose();            
        }
    }
    
    useEffect(() => {
        // console.log( values );
    }, [msgError])
    
    const showError = ( msg, tipo ) => {
        ShowError('error', msg);
        dispatch( setError(null) );
    }

    return (
        <>
            <Dialog open={open} maxWidth='md' fullWidth={true} PaperComponent={ PaperComponent }>
                <DialogTitle  id="Cap-Traspasos-Bancarios"> Traspasos Bancarios </DialogTitle>
                <DialogContent dividers={ true }>
                    <DialogContentText id="scroll-dialog-description" >

                        { msgError && showError( msgError, 'error') }
                        <form autoComplete='off'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <LookupBanco required autoFocus name='cta' label='Cuenta origen' cta={ origen.cta } setValues={setOrigen} />
                                </Grid>    
                                <Grid item xs={12} md={6}>
                                    <LookupBanco required name='cta' label='Cuenta destino' cta={ destino.cta } setValues={setDestino} />
                                </Grid>    
                                <Grid item xs={6} md={3}>
                                    <TextDate required label='Fecha' value={wDate} onChange={ date => setwDate( date ) } />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <TextEdit type='number' name='importe' value={importe} label='Importe' onChange={ onInputChange } error={fieldsErr.importe } 
                                    inputProps={{min: 0, style: { textAlign: 'right' }}} />
                                </Grid>    
                            </Grid>
                        </form>

                    </DialogContentText>
                </DialogContent>
                
                <DialogActions>
                    <BotonOK type="submit" variant='outlined' onClick={ acGrabar } >
                        Grabar
                    </BotonOK>
                    <BotonCancel variant='outlined' onClick={ onClose } >
                        Cancelar
                    </BotonCancel>
                </DialogActions>


            </Dialog>
        </>
    )
}
