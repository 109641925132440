import { blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    barTitulo: {

      // background: blue[700],
      // color: '#FFF',

      // background: theme.palette.titulo.background,
      // color: theme.palette.titulo.color,
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    panel: {
      display: 'flex',
      margin: '0',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(0, 1, 2)
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(0),
      marginTop: theme.spacing(9),
    },
    
  }));