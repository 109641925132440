import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { format } from 'date-fns';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { setError, setLoading } from '../../actions/ui';
import { getEdoCtaBan } from '../../actions/acBancos';
import { CustomReporte } from '../CustomReporte/CustomReporte';
import { LookupBanco } from '../../components/LookupBanco/LookupBanco';
import { ShowError } from '../../components/ShowError/ShowError';
import { setPesos, showSuccess } from '../../Utils/Util';

import { styles } from '../CustomCatalogos/StylesCatalogos';

const Paramateros = ({ cta, setValues }) => {
    return (
        <>
            <Grid container spacing={2}> 
                <Grid item xs={12} md={6}>
                    <LookupBanco required name='cta' label='Cuenta' cta={ cta } setValues={setValues} />
                </Grid>    
            </Grid>
        </>
    )
}

const onClickEdit = () => {
    showSuccess('Editar')
}

const onClickDelete = () => {
    showSuccess('Borrar')
}


export const EdoCtaBan = () => {
    const classes = styles();
    const columns = [
        {name: 'concilia', label: ' ', options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <>
                  { value === '*' ? <CheckBoxIcon fontSize="small" color='primary'/>
                        : <CheckBoxOutlineBlankIcon fontSize="small" color='primary'/>
                  }
                </>
              );
            }
          }
        },    
        {name: 'fecha', label: 'Fecha', options:{filter: false, setCellProps: () => ( {style: {whiteSpace:'nowrap'}})}},
        {name: 'concepto', label: 'Concepto', options:{filter: false} },
        {name: 'cheque', label:'Refer. / Cheque', options:{setCellProps: () => ( {style: {whiteSpace:'nowrap'}})}},
        {name: 'cargo', label:'Retiros / Cargos', options:{
            setCellProps: () => ({ align: 'right' }),
            customBodyRender: (value, tableMeta, updateValue) => setPesos( value ),
            setCellHeaderProps: () => ({ align: 'right'}),
        }},
        {name: 'abono', label:'Depositos / Abonos', options: { 
            setCellProps: () => ({ align: 'right' }),
            customBodyRender: (value, tableMeta, updateValue) => setPesos( value ),
            setCellHeaderProps: () => ({ align: 'right'}),
        }},        
        {name: 'saldo', label: 'Saldo', options:{
            setCellProps: () => ({ align: 'right' }),
            customBodyRender: (value, tableMeta, updateValue) => setPesos( value ),
            setCellHeaderProps: () => ({ align: 'right'}),
        }},
        {name: 'saldoc', label: 'Saldo Conciliado', options:{
            setCellProps: () => ({ align: 'right' }),
            customBodyRender: (value, tableMeta, updateValue) => setPesos( value ),
            setCellHeaderProps: () => ({ align: 'right'}),
        }},

        { name: 'acciones', label: 'Acciones',
        options: {filter: false, sort: false, empty: true, print: false, display: false,
            setCellProps: () => ( {style: {whiteSpace:'nowrap'}}),
            customBodyRenderLite: (dataIndex) => {
            return (
              <>
                <button className={ classes.acciones } onClick={() => onClickEdit( data[dataIndex] )}>
                  <EditIcon fontSize="small"/>
                </button>
                <button className={ classes.acciones } onClick={() => onClickDelete( data[dataIndex] )}>
                  <DeleteIcon fontSize="small"/>
                </button>
              </>
            );
          },
          }
        }

    ]

    const opciones = {
        sort: false,
        filter: false,
        draggableColumns: { enabled: false, },
        // onRowClick: (row, index) => { console.log(row[0] ); },
    }


    const dispatch = useDispatch();
    const { msgError } = useSelector(state => state.ui);
    const [data, setData] = useState([]);
    const [ejecuta, setEjecuta] = useState( false );
    const [params, setParams] = useState({
        w1: '',
        w2: '',
        cta: '',
    });
    
    const [values, setValues] = useState({ 
        idbanco: -1,
        cta: '',
        titular: '',
        banco: ''
    })
    const {cta, titular } = values;

    const showError = ( msg, tipo ) => {
        ShowError('error', msg);
        dispatch( setError(null) );
    }

    const acExecute = ( fechas ) => {
        setEjecuta( false );
        if (fechas.wDate1 === null ) {
            dispatch( setError('La fecha inicial es obligatoria'));
            return;
        }
        if (fechas.wDate2 === null ) {
            dispatch( setError('La fecha final es obligatoria'));
            return;
        }
        if (values?.cta.length === 0 ) {
            dispatch( setError('Falta la cuenta'));
            return;
        }

        setParams({
            w1: format(fechas.wDate1 , 'yyyyMMdd'),
            w2: format(fechas.wDate2 , 'yyyyMMdd'),
            cta: values.cta
        })
        setEjecuta( true );
    }

    useEffect(() => {
        if ( ejecuta ){ 
            dispatch( setLoading(true) );
            const fetchData = async() => {
               const result = await getEdoCtaBan( params.w1, params.w2, params.cta );
               setData([ ...result ]);           
               dispatch( setLoading(false) );
            };
            fetchData(); 
        }
            return () => { setData([]); }
    }, [dispatch, ejecuta, params.cta, params.w1, params.w2]);

  
    return (
        <>  
            { msgError && showError( msgError, 'error') }
            <CustomReporte 
                titulo='Estado de Cuenta'  
                campos={ columns } 
                data={ data } 
                extra={ <Paramateros cta={cta} titular={titular} setValues={setValues} /> } 
                acExecute={ acExecute }
                opciones={ opciones }
                
            />
        </>
    )
}
