import { types } from "../types/types";


export const mainMenuOpen = () => ({
    type: types.uiOpenMenu
});

export const mainMenuClose = () => ({
    type: types.uiCloseMenu
});

export const userMenuOpen = () => ({
    type: types.uiOpenMenuUser
});

export const userMenuClose = () => ({
    type: types.uiCloseMenuUser
});

export const userMenuanchorEl = ( valor ) => ({
    type: types.uiMenuUserAnchorEl,
    payload: valor
})

export const uiDark = () => ({
    type: types.uiDarkMode
});

export const uiLight = () => ({
    type: types.uiLightMode
});

export const setError = ( err ) => ({
    type: types.uiMarcaError,
    payload: err
})

export const setLoading = ( status ) => ({
    type: types.uiSetLoading,
    payload: status
})


