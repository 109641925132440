import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";
import { useSelector } from 'react-redux';
import { 
    Accordion, 
    AccordionDetails, 
    AccordionSummary, 
    Fab, 
    Grid, 
    Typography 
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { Waiting } from '../../components/Waiting/Waiting';
import { styles } from './StylesReportes';
import { TextDate } from '../../components/TextDate/TextDate';
  
const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableHeadCell: {
        root: {
        //   border: 'solid 1px #ccc',
          padding: '5px',
        }
      },
      MuiTableCell: {
        root: {
        //   border: '1px solid #ddd'
        },
      },
      MUIDataTableBodyCell: {
        root: {
          padding: '4px',
        }
      },
    }
});

export const CustomReporte = ({ titulo, campos, data, acExecute, extra, opciones={} }) => {
    const classes = styles();
    const { loading } = useSelector(state => state.ui);
    const [fechas, setFechas] = useState({
        wDate1: new Date(),
        wDate2: new Date()
    });

    const { wDate1, wDate2 } = fechas;

    const txtEtiquetas = {
        body: {
          noMatch: "UPS, No se encontraron registros...",
          toolTip: "Ordenar",
          columnHeaderTooltip: column => `Ordenar por ${column.label}`
        },
        pagination: {
          next: "Sig Pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Registros por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtros",
        },
        filter: {
          all: "Todo",
          title: "FILTROS",
          reset: "LIMPIAR",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Show/Hide Table Columns",
        },
        selectedRows: {
          text: "row(s) selected",
          delete: "Borrar",
          deleteAria: "Delete Selected Rows",
        },        
    }

    const options = {
        filterType: 'dropdown',
        responsive: 'standard',
        fixedHeader: true,
        // pagination: false,
        tableBodyHeight: 'calc(100vh - 240px)',
        rowsPerPage: 50,
        rowsPerPageOptions: [10, 50, 100, 500],
        searchPlaceholder: 'Buscar...',
        selectableRows: 'none',
        draggableColumns: { enabled: true, },
        textLabels: txtEtiquetas,
        // onRowClick: (row, index) => { console.log(row, index ); },

        ...opciones
    };

    const acRun = () => {
        acExecute( fechas )
    }

    return (
      <div className={ classes.tabla }>
          <div>
            <Accordion defaultExpanded >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography >Parametros</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={3}>
                            <TextDate 
                                label='Fecha Inicial' 
                                value={wDate1} 
                                autoFocus 
                                onChange={ date => setFechas({ 
                                ...fechas, 
                                wDate1: date}) } 
                                required
                            />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <TextDate 
                                label='Fecha Final' 
                                value={wDate2} 
                                onChange={ date => setFechas({ 
                                ...fechas, 
                                wDate2: date}) } 
                                required
                            />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <Fab  size="large" className={ classes.iconAdd } aria-label="execute" onClick={ acRun } >
                                <SearchIcon />
                            </Fab> 
                        </Grid>

                        <Grid item xs={12} md={12}>  
                            { extra } 
                        </Grid>


                    </Grid>
                </AccordionDetails>   
            </Accordion>

            { loading && <Waiting />}
            <MuiThemeProvider theme={getMuiTheme()}> 
                <MUIDataTable 
                    className={ classes.root }
                    title={ titulo }
                    columns={ campos }
                    options={ options }
                    data={ data }
                />
            </MuiThemeProvider>
          </div>
      </div>
    )
}
