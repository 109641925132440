import Swal from 'sweetalert2';
import './ShowError.css';

export const ShowError = (tipo, msg) => {
    return (
        Swal.fire({
            // position: 'top-end',
            icon: tipo,
            // title: 'Error',
            text:  msg,
            // toast: true,
            customClass: {
                container: 'my-swal'
            }
          })
    )
}
