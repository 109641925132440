import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { FormHelperText } from '@material-ui/core';

export const TextDate = ( props ) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                // disableToolbar
                variant="inline"
                autoOk='true'
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="dense"
                invalidDateMessage='Fecha no valida (dd/mm/yyyy)'
                fullWidth
                error={ props.required && props.value === null}
                { ...props }
            />
            <FormHelperText error >
                { props.required && props.value === null ? `El campo ${props.label} es obligatorio` : <span></span> }
            </FormHelperText>

        </MuiPickersUtilsProvider>
    )
}