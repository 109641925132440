import { types } from "../types/types";

const inicial = {
    openMenu: false,
    openMenuUser: false,
    loading: false,
    darkState: false,
    menuID: 0,
    userAnchorEl: null,
    msgError: null,
}

export const uiReducer = ( state = inicial, action)  => {
    switch ( action.type ) {
        case types.uiOpenMenu:
            return {
                ...state,
                openMenu: true
            }
        case types.uiCloseMenu: 
            return {
                ...state,
                openMenu: false
            }
        case types.uiOpenMenuUser:
            return {
                ...state,
                openMenuUser: true
            }
        case types.uiCloseMenuUser:
            return {
                ...state,
                openMenuUser: false
            }
        case types.uiDarkMode:
            return {
                ...state,
                darkState: true
            }
        case types.uiLightMode:
            return {
                ...state,
                darkState: false
            }
        case types.uiMenuUserAnchorEl:
            return {
                ...state,
                userAnchorEl: action.payload
            }
        case types.uiMarcaError: 
            return {
                ...state,
                msgError: action.payload
            }
        case types.uiSetLoading:
            return {
                ...state,
                loading: action.payload
            }

        default:
            return state
    }
}