import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles( (theme) => ({
	boxAdd: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: '20px',
		marginBottom: '-10px'
	},
	iconAdd: {
		backgroundColor: theme.palette.primary.main,
        marginLeft: '5px',
		transition: 'all ease 0.3s',
		'&:hover': {
			background: theme.palette.primary.dark,
			transform: 'scale(1.2)',
		},
		color: '#fff',
	},
    }),
    { name: "myStyles" }
);
