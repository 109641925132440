import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { getBancos } from '../../actions/acBancos';
import { WaitCircular } from '../Waiting/Waiting';
import { FormHelperText, Typography } from '@material-ui/core';

const filterOptions = createFilterOptions({
    stringify: ({ cta, titular, banco }) => `${cta} ${titular} ${banco}`
});

export const LookupBanco = ( props ) => {

    const { cta, setValues, required } = props;
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;

    const [value, setValue] = useState(options[0]);
    const [inputValue, setInputValue] = useState( cta );  

    useEffect(() => {
        let active = true;    
        if (!loading) {
          return undefined;
        }
    
        (async () => {
          const response = await getBancos();
          const data = [ ...response ]
    
          if (active) {
            setOptions( data );
          }
        })();
    
        return () => {
          active = false;
        };
    }, [loading]);

    useEffect(() => {
    if (!open) {
        setOptions([]);
    }
    }, [open]);

    const onChangeValue = ( newValue ) => {
        setValue( newValue);
        setValues({ ...newValue })
    }

    const onChangeInput = ( newInputValue ) => {
        setInputValue( newInputValue );
    }

    return (
      <>
      <Autocomplete
        // id="edCta"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionSelected={(option, value) => option.idbanco === value.idbanco}

        filterOptions={filterOptions}
        getOptionLabel={( { cta, titular }) => {
            // return `${cta} ${titular}`;
            return titular;
        } }
        filterSelectedOptions
        renderOption={({ cta, titular, banco }) => {
            return (
              <div>
                <Typography variant='subtitle2'> {cta}  {banco} </Typography>
                <Typography variant='subtitle1'>{titular}</Typography>
              </div>
            );
        }}
        fullWidth

        options={options}
        loading={loading}

        value={value}
        onChange={(event, newValue) => { onChangeValue(newValue); }}

        inputValue={inputValue}
        onInputChange={(event, newInputValue) => { onChangeInput(newInputValue); }}

        renderInput={(params) => (
          <TextField
            {...params}
            {...props}
            required={required}
            error={ required && (cta === undefined || cta === '')}
            // label='Cuenta'
            variant="outlined"
            margin='dense'
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <WaitCircular /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
        <FormHelperText error >
            { (required && (cta === undefined || cta ==='')) ? 'El campo cuenta es obligatorio' : <span></span> }
        </FormHelperText> 


      </>
    );
  }


