import React, { useEffect, useState } from 'react';
import { 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    Grid,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { format } from 'date-fns';

import { useForm } from '../../hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { setError } from '../../actions/ui';
import { ShowError } from '../../components/ShowError/ShowError';
import { TextEdit } from '../../components/TextEdit/TextEdit';
import { saveMovBan } from '../../actions/acBancos';
import { LookupBanco } from '../../components/LookupBanco/LookupBanco';
import { TextDate } from '../../components/TextDate/TextDate';
import { BotonCancel, BotonOK } from '../../Styles/Styles';
import { showSuccess } from '../../Utils/Util';

function PaperComponent(props) {
    return (
      <Draggable handle="#Cap-Movimientos-Bancarios" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}

export const Movimientos = () => {

    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const [wDate, setwDate] = useState(new Date());
    const [banco, setBanco] = useState({
        idbanco: -1,
        cta: '',
        titular: '',
        banco: ''
    });

    const [fieldsErr, setFieldsErr] = React.useState({
        ValidaDatos: false,
        ctaErr: false,
        fechaErr: false,
        Importe0Err: false,
        Importe2Err: false,
    });

    const [ values, onInputChange, reset ] = useForm({
        cheque: '',
        concepto: '',
        cargo: 0,
        abono: 0,
        concilia:  ' ',
        status:  'MO',
        tipopago: null,
    });

    const  {
        cheque,
        concepto,
        cargo,
        abono,
    } = values;

    const { msgError } = useSelector(state => state.ui);

    const onClose = () => {
        setOpen(false)
    }

    const acGrabar = async (e) => {
        e.preventDefault();
        setFieldsErr({
            ...fieldsErr,
            ctaErr: banco.cta?.length === 0 ? true : false,
            importe0Err: (cargo === 0  && abono === 0) ? true : false,
            importe2Err: (cargo !== 0  && abono !== 0) ? true : false,
        });

        if ( banco.cta === undefined || banco.cta?.length === 0 ) {
            dispatch( setError('ERROR: Numero de cuenta no valido') );
            return;
        }

        if ( cargo === 0  && abono === 0 ) {
            dispatch( setError('ERROR: Se debe de registrar un importe') );
            return;
        }
        
        if ( cargo !== 0 && abono !== 0 ) {
            dispatch( setError('ERROR: No se puede registrar movimiento con retiro y deposito simultáneamente') );
            return;
        }
        if ( cargo < 0 || abono < 0 ) {
            dispatch( setError('ERROR: Los importes no pueder ser negativos') );
            return;
        }

        const manda = {
            ...values,
            idbanco: banco.idbanco,
            cta: banco.cta,
            fecha: format(wDate, 'yyyyMMdd'),
        }

        const resp = await saveMovBan( manda );
        if (resp.coderesult !== 200) {
            dispatch( setError( resp.Message ));
        } else {
            showSuccess('Registrado OK');
            console.log('Listo');
            reset();
            // onClose();            
        }
    }
    
    useEffect(() => {
        // console.log( values );
    }, [msgError])
    
    const showError = ( msg, tipo ) => {
        ShowError('error', msg);
        dispatch( setError(null) );
    }

    return (
        <>
            <Dialog open={open} maxWidth='md' fullWidth={true} PaperComponent={ PaperComponent }>
                <DialogTitle  id="Cap-Movimientos-Bancarios"> Movimientos Bancarios </DialogTitle>
                <DialogContent dividers={ true }>
                    <DialogContentText id="scroll-dialog-description" >

                        { msgError && showError( msgError, 'error') }
                        <form autoComplete='off'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <LookupBanco required autoFocus name='cta' label='Cuenta' cta={ banco.cta } setValues={setBanco} />
                                </Grid>    
                                <Grid item xs={12} md={3}>
                                    <TextDate required label='Fecha' value={wDate} onChange={ date => setwDate( date ) } />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextEdit name='cheque' value={cheque} label='Refer' fullWidth onChange={ onInputChange }/>
                                </Grid>    
                                <Grid item xs={12} md={12}>
                                    <TextEdit name='concepto' value={concepto} label='Concepto' fullWidth onChange={ onInputChange }/>
                                </Grid>    
                                <Grid item xs={12} md={3}>
                                    <TextEdit type='number' name='cargo' value={cargo} label='Retiro' fullWidth onChange={ onInputChange } error={fieldsErr.importe0Err || fieldsErr.importe2Err } 
                                    inputProps={{min: 0, style: { textAlign: 'right' }}} />
                                </Grid>    
                                <Grid item xs={12} md={3}>
                                    <TextEdit type='number' name='abono' value={abono} label='Deposito' fullWidth onChange={ onInputChange } error={fieldsErr.importe0Err || fieldsErr.importe2Err } 
                                    inputProps={{min: 0, style: { textAlign: 'right' }}} />
                                </Grid>    
                            </Grid>
                        </form>

                    </DialogContentText>
                </DialogContent>
                
                <DialogActions>
                    <BotonOK type="submit" variant='outlined' onClick={ acGrabar } >
                        Grabar
                    </BotonOK>
                    <BotonCancel variant='outlined' onClick={ onClose } >
                        Cancelar
                    </BotonCancel>
                </DialogActions>


            </Dialog>
        </>
    )
}
