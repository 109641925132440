import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';  
import CssBaseline from '@material-ui/core/CssBaseline';

import { getUser } from '../actions/auth';
import { LoginPage } from '../pages/LoginPage/LoginPage';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { Routes } from './Routes';
import { Typography } from '@material-ui/core';

export const AppRouter = () => {

    const [ checking, setChecking ] = useState(true);
    const { isLogin } = useSelector(state => state.auth);

    const dispatch = useDispatch();

    useEffect(() => {
        const ul = localStorage.getItem('ul');
        if (ul !== null && ul !== '' ) {
            dispatch( getUser( ul ));
        }
        setChecking( false );
    }, [ dispatch ])


    if ( checking ) {
        return (
            <Typography align='center' variant="h1" component="h2">
                ahi voy...
            </Typography>
        )
    }

    return (
        <Router>
            <CssBaseline />
            <div>
                <Switch>
                    <PublicRoute 
                        exact 
                        path='/login' 
                        component={ LoginPage } 
                        isLogin={ isLogin } 
                    />
                    <PrivateRoute 
                        path='/' 
                        component={ Routes } 
                        isLogin={ isLogin } 
                    />
                    {/* <Redirect to='/login' /> */}
                </Switch>
            </div>
        </Router>
    )
}
