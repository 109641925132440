import { types } from "../types/types";

const inicial = {
    nombre: '',
    uid: '',
    role: '',
    iniciales: '',
    imagen: '',
    mail: '',
    isLogin: false,
}

export const userReducer = ( state = inicial, action ) => {
    switch ( action.type ) {
        case types.userSetUsuario:
            return {
                ...state,
                nombre: action.payload.nombre,
                uid: action.payload.iduser,
                mail: action.payload.mail,
                isLogin: true,
            } 
        case types.userLogout:
            return {
                isLogin: false
            }

        default:
            return state;
    }
}