import imgLogo from '../assets/img/logo.png';
// import imgLogo from '../assets/img/logoW.png';

const inicial = {
    nombre: 'Bancos Gorostiza',
    mail: '',
    logo: imgLogo,

}
export const ciaReducer = ( state=inicial, action ) => {
    switch ( action.type ) {
    
        default:
            return state;
    }
}