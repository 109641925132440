import { types } from "../types/types"
import { setUser } from './acUsers';
import { setError, setLoading } from "./ui";
  
export const login = ( user, pass ) => {
    return ( dispatch ) => {        
        const url = `${ window.$baseApi }/TAuth/ValidaLogin/${ user }/${ pass }`;    
        try {
            dispatch( setLoading(true));
            fetch( url ).then( res =>{
                res.json().then ( data => {
                    if ( data !== null ) {
                        localStorage.setItem('ul', data[0].iduser+':'+data[0].token );                        
                        dispatch( setLogin( data[0] ));
                        dispatch( setUser( data[0] ));
                        dispatch( setLoading(false));
                    } else {
                        dispatch( setLoading(false));
                        dispatch( setError('Usuario no valido') );
                    }
                })
            })
            .catch(err => {
                dispatch( setLoading(false));
                dispatch( setError( err ) );
            })
            
        } catch ( err ) {
            dispatch( setLoading(false));
            dispatch( setError( err ) );
        }     
    }    
}

export const getUser = ( user ) => {
    return ( dispatch ) => {
        const url = `${ window.$baseApi }/TAuth/GetUser/${ user }`;
        try {
            dispatch( setLoading(true));
            fetch( url ).then( res =>{
                res.json().then ( data => {
                    if ( data !== null ) {
                        dispatch( setLogin( data[0].nombre, data[0].iduser ));
                        dispatch( setUser( data[0] ));
                        dispatch( setLoading(false));
                    } else {
                        dispatch( setLoading(false));
                        dispatch( setError('Usuario no valido') );
                    }
                })
            })
            .catch(err => {
                dispatch( setLoading(false));
                dispatch( setError( err ) );
            })
            
        } catch ( err ) {
            dispatch( setLoading(false));
            dispatch( setError( err ) );
        }     
    }
}

const setLogin = ( usuario ) => ({
    type: types.login,
    payload: {
        isLogin: true,
        uid: usuario.token,
        name: usuario.nombre,
    }
})

export const logout = () => {
    return ( dispatch ) => {
        const user = localStorage.getItem('ul');
        const url = `${ window.$baseApi }/TAuth/signOut/${ user }`;
        localStorage.setItem('ul','');
        try {
            dispatch( setLoading(true));
            dispatch( setUser( {} ));
            dispatch( setLogout() );
            dispatch( setLoading(false));

            fetch( url ).then( res =>{
                res.json().then ( data => {
                    if ( data !== null ) {
                        // dispatch( setUser( {} ));
                        dispatch( setLoading(false));
                        // dispatch( setLogout() );
                    } else {
                        dispatch( setLoading(false));
                    }
                })
            })
            .catch(err => {
                dispatch( setLoading(false));
                dispatch( setError( err ) );
            })
            
        } catch ( err ) {
            dispatch( setLoading(false));
            dispatch( setError( err ) );
        }     
    }
}

const setLogout = () => ({
    type: types.logout
})