import { 
    createMuiTheme, 
    // makeStyles, 
    // withStyles 
} from '@material-ui/core/styles';
import { red, deepOrange, green, pink, orange, teal, blue, indigo, lightBlue } from '@material-ui/core/colors';
// import * as colores from '@material-ui/core/colors';
import { Button, ThemeProvider } from '@material-ui/core';

export const theme = createMuiTheme({
    palette: {
        // type: "dark",
        type: 'light',
        primary: {
            main: blue[700],
        },
        secondary: {
            // main: '#11cb5f',
            main: deepOrange[500],
        },
        titulo: {
            background: blue[600],
            color: '#FFF',
            // background: 'linear-gradient(rgb(55, 122, 220), rgb(51,115,209) 50%, rgb(49, 100, 198)',
        },
        panelCIA: {
            // background: green[700],
            background: 'rgb(36, 82, 142)',
            color: '#fff',
        },
        aceptar: {
            // main: '#5c6ac4',
            main: green[500],
        },
        cancelar: {
            main: red[500],
        },
    },
});

export const ThemeBotones = createMuiTheme({
    palette: {
        primary: green,
        secondary: pink,
    }
});

// export const BotonOK = withStyles((theme) => ({
//     root: {
//         color: theme.palette.getContrastText(green[300]),
//         backgroundColor: green[300],
//         '&:hover': {
//             backgroundColor: green[700],
//         },
//     },
// }))(Button);

// export const BotonCancel = withStyles((theme) => ({
//     root: {
//         color: theme.palette.getContrastText(red[600]),
//         backgroundColor: red[600],
//         '&:hover': {
//             backgroundColor: red[900],
//         },
//     },
// }))(Button);

export const BotonOK = ( props ) => {
    return (
        <ThemeProvider theme={ThemeBotones}>
            <Button variant="contained" color="primary" { ...props }>
                Aceptar
            </Button>
        </ThemeProvider>
    )
}

export const BotonCancel = ( props ) => {
    return (
        <ThemeProvider theme={ThemeBotones} >
            <Button variant="contained" color="secondary" { ...props }>
                Cancelar
            </Button>
        </ThemeProvider>
    )
}
