import React from 'react';
// import CssBaseline from '@material-ui/core/CssBaseline';
import { Header } from '../../components/Header/Header';
import { MainMenu } from '../../components/MainMenu/MainMenu';

export const HomePage = () => {
    return (
        <>
            <Header />
            <MainMenu />
        </>
    )
}
