import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import StylesHeader from '../components/Header/StylesHeader';
import { Bancos } from '../pages/Bancos/Bancos';
import { Movimientos } from '../pages/Bancos/Movimientos';
import { EdoCtaBan } from '../pages/EdoCtaBan/EdoCtaBan';
import { HomePage } from '../pages/HomePage/HomePage';
import { RepSaldos } from '../pages/RepSaldos/RepSaldos';
import { Traspasos } from '../pages/Traspasos/Traspasos';

export const Routes = () => {
    const classes = StylesHeader();
    return (
        <>
            <HomePage />
            <main className={ classes.content }>
                <div className={ classes.toolbar }>
                    <Switch>
                        <Route exact path='/home' component={ HomePage } />
                        <Route exact path='/cuentas' component={ Bancos } />
                        <Route exact path='/movimientos' component={ Movimientos } />
                        <Route exact path='/edoctaban' component={ EdoCtaBan } />
                        <Route exact path='/saldos' component={ RepSaldos } />
                        <Route exact path='/traspasos' component={ Traspasos } />

                        <Redirect to='/home' />
                    </Switch>
                </div> 
            </main>
            
        </>
    )
}
