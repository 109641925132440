import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
// import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import StylesPanelEmpresa from './StylesPanelEmpresa';

export const PanelEmpresa = () => {
    const classes = StylesPanelEmpresa();
    const { nombre, logo } = useSelector(state => state.cia);
    return (
        <div className={ classes.panel }>
            <Box className={ classes.panel }>
                <img src={ logo } alt="Logo"  className={ classes.logo }/>
                {/* <AccountBalanceIcon 
                    className={ classes.texto } 
                    fontSize="large" 
                /> */}
                <Typography 
                    variant='caption' 
                    color="initial"
                    align='center'
                    mt={8}
                >
                    { nombre }
                </Typography>            
            </Box>
            <Divider />
        </div>
    )
}
