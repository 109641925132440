import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles( (theme) => ({
    root: {
        height: "100hv"
    },
    tabla: {
        flex: '1',
        height: '100hv',
    },
    wrapper: {
    },
	boxAdd: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: '20px',
		marginBottom: '-10px'
	},
	iconAdd: {
		backgroundColor: theme.palette.primary.main,
        marginLeft: '5px',
		transition: 'all ease 0.3s',
		'&:hover': {
			background: theme.palette.primary.dark,
			transform: 'scale(1.2)',
		},
		color: '#fff',
	},
	btnCancel: {
		color: '#ff0f00',
		border: '1px solid rgb(234 77 66 / 50%)',
		'&:hover': {
			borderColor: '#ff0f00'
		}
	},
	btnSave: {
		color: '#1ca922',
		border: '1px solid rgb(76 175 80 / 50%)',
		'&:hover': {
			borderColor: '#1ca922'
		}
	},
	acciones: {
		border: 'none',
		background: 'none',
		cursor: 'pointer',
		outline: 0,
		color: 'rgba(0, 0, 0, 0.54)',
		padding: '0 12px',
		'&:hover': {
			color: theme.palette.secondary.main,
		}
	
	}
    }),
    { name: "myStyles" }
);
