import { makeStyles } from '@material-ui/core';

// export default makeStyles((theme) => ({
//     panel: {
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         backgroundColor: 'rgb(73, 162, 46)',
//         padding: theme.spacing(1),
//         // maxWidth: '250px',
//     },
//     logo: {
//         maxWidth: '120px',
//         // margin: theme.spacing(1, 0, 2),
//     },
//     mb_3: {
//         marginBottom: theme.spacing(3),
//     }
// }));

export default makeStyles((theme) => {
    return ({
    panel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.panelCIA.background,
        // color: theme.palette.primary.contrastText,
        color: theme.palette.panelCIA.color,
        padding: theme.spacing(1),
        // width: '260px',
    },
    logo: {
        maxWidth: '120px',
        borderRadius: '90px',
        width: '118px',
        // margin: theme.spacing(1, 0, 2),
    },
    mb_3: {
        marginBottom: theme.spacing(3),
    },
    texto: {
        fontSize: '64px',
    }
    })
}

);

