import React from 'react';
import MUIDataTable from "mui-datatables";
import { Box, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
// import CustomToolbar from './CustomToolbar';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector } from 'react-redux';
import { Waiting } from '../../components/Waiting/Waiting';

import { styles } from './StylesCatalogos';

export const CustomCatalogos = ({ titulo, campos, data, onClickAdd, onClickEdit, onClickDelete }) => {
    const classes = styles();
    const { loading } = useSelector(state => state.ui);

    const txtEtiquetas = {
        body: {
          noMatch: "UPS, No se encontraron registros...",
          toolTip: "Ordenar",
          columnHeaderTooltip: column => `Ordenar por ${column.label}`
        },
        pagination: {
          next: "Sig Pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Registros por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtros",
        },
        filter: {
          all: "Todo",
          title: "FILTROS",
          reset: "LIMPIAR",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Show/Hide Table Columns",
        },
        selectedRows: {
          text: "row(s) selected",
          delete: "Borrar",
          deleteAria: "Delete Selected Rows",
        },        
    }

    const options = {
        filterType: 'dropdown',
        responsive: 'standard',
        fixedHeader: true,
        // pagination: false,
        tableBodyHeight: 'calc(100vh - 220px)',
        rowsPerPage: 50,
        rowsPerPageOptions: [10, 50, 100, 500],
        searchPlaceholder: 'Buscar...',
        selectableRows: 'none',
        draggableColumns: { enabled: true, },
        textLabels: txtEtiquetas,

        // resizableColumns: true,
        // onRowClick: (row, index) => { console.log(row, index ); },
        // customToolbar: () => {
        //   return (
        //     <CustomToolbar />
        //   );
        // },

        setTableProps: () => {
        return {
            // padding: 'none',

            // material ui v4 only
            // size: 'medium',
        };

        },
    };

    let columnas;
    const AgregaAcciones = () => {
      columnas = [...campos];
      columnas.push(
        { name: 'acciones', label: 'Acciones',
        options: {filter: false, sort: false, empty: true, print: false,
            setCellProps: () => ( {style: {whiteSpace:'nowrap'}}),
            customBodyRenderLite: (dataIndex) => {
            return (
              <>
                <button className={ classes.acciones } onClick={() => onClickEdit( data[dataIndex] )}>
                  <EditIcon fontSize="small"/>
                </button>
                <button className={ classes.acciones } onClick={() => onClickDelete( data[dataIndex] )}>
                  <DeleteIcon fontSize="small"/>
                </button>
              </>
            );
          },
          }
        }
      )
    }

    AgregaAcciones();

    return (
      <div className={ classes.tabla }>
          <Box className={classes.boxAdd} >
            <Fab 
              size="small" 
              className={ classes.iconAdd } 
              aria-label="add" 
              onClick={ onClickAdd }              
            >
              <AddIcon />
            </Fab> 
          </Box>

          <div>
            { loading && <Waiting />}
            <MUIDataTable 
                className={ classes.root }
                title={ titulo }
                columns={ columnas }
                options={ options }
                data={ data }
            />
          </div>
      </div>
    )
}
