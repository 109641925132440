import React from 'react';
import { Provider } from 'react-redux';
import { AppRouter } from './routers/AppRouter';
import { store } from './store/store';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './Styles/Styles'

window.$baseApi = 'http://gregorysoft.viewdns.net:38500/apiBancos/1.0.0.0'; 
// window.$baseApi = 'http://192.168.1.30:37500/apiBancos/1.0.0.0'; 
// window.$baseApi = 'http://192.168.1.30:38500/apiBancos/1.0.0.0'; 


export const BiosBANK = () => {
    // const [locale, setLocale] = React.useState('esES');

    const tipoNavegador = () => {
        const agente = window.navigator.userAgent;
        const navegadores = ["Chrome", "Firefox", "Safari", "Opera", "Trident", "MSIE", "Edge"];

        for(var i in navegadores){
            if(agente.indexOf( navegadores[i]) !== -1 ){
                return navegadores[i];
            }
        }

    } 

    if ( tipoNavegador() === 'MSIE') {
        alert('Tu navegador no es compatible');
        return;
    }
    else {

        return (
            <Provider store={ store } >
                <ThemeProvider theme={theme} >
                    <AppRouter />
                </ThemeProvider>
            </Provider>
        ) 
    }
}
