import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, 
  Divider, 
  Drawer, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText 
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { useStyles } from './StylesMainMenu';
import { mainMenuClose } from '../../actions/ui';
import { MainMenu as menuPrincipal } from '../../data/menus';
import { PanelEmpresa } from '../PanelEmpresa/PanelEmpresa';
  
  const MenuContent = ({ opciones, acRegresar, onOpenItems, acEjecuta }) => {
  const classes = useStyles();  
  let lastOpcion;

  const nodo = opciones[0];
  if ( nodo && nodo.parentid > 0 ) {
      const padre = menuPrincipal.find( item => item.id === nodo.parentid );
      lastOpcion = (
          <Box className={ classes.menuTitle } boxShadow={3} onClick={ () => acRegresar( padre ) }>
              <ArrowBackIcon 
                  className={ classes.btnBack } 
              />
              <Box component='span' >
                  { padre.label }
              </Box>
          </Box>
      )
  }

  return (        
      <Box className={ classes.drawerPaper } >
          <Divider />
          { lastOpcion }
          <List>
              { opciones.map( opcion => {
                  let subOpciones;

                  if ( opcion.link !== '' ) {
                      subOpciones = (
                          <Link key={ opcion.id } to= { opcion.link } className={ classes.link } >
                              <ListItem button onClick={ () => acEjecuta( opcion ) } >
                                <ListItemIcon className={ classes.iconMenu } > 
                                  { opcion.icon } 
                                </ListItemIcon>
                                <ListItemText primary={ opcion.label } />
                              </ListItem>
                          </Link>
                          )
                  } else if ( opcion.link === '') {
                      subOpciones = (
                          <ListItem key={ opcion.id } button onClick={ () => onOpenItems( opcion ) } >
                              <ListItemIcon className={ classes.iconMenu } > 
                                { opcion.icon } 
                              </ListItemIcon>
                              <ListItemText primary={ opcion.label } />
                              <ArrowRightIcon color="action" fontSize="small" />
                          </ListItem>
                      )
                  }
                  return subOpciones;
              })}
          </List>
      </Box>
  )    
} 

export const MainMenu = () => {
  const classes = useStyles();
  const { openMenu } = useSelector(state => state.ui);  
  const dispatch = useDispatch();

  const menu0 = () => menuPrincipal.filter( item => item.parentid === 0);
  const [currentMenu, setCurrentMenu] = useState( menu0() );

  const handleCloseMenu = () => {
    dispatch( mainMenuClose() );
  };

  const acRegresar = ( padre ) => {
    const sub = menuPrincipal.filter( item => item.parentid === padre.parentid );
    setCurrentMenu( sub );
  }

  const onOpenItems = ( opcion ) => {
    const sub = menuPrincipal.filter( item => item.parentid === opcion.id );
    setCurrentMenu( sub );
  }

  const onClick = ( opcion ) => {
    handleCloseMenu();
  }

  return (
    <div className={classes.root}>
      <Drawer
        variant='temporary'
        open={ openMenu }
        onClose={ handleCloseMenu }
        className={ classes.drawer }
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <PanelEmpresa />
        <MenuContent 
          opciones={ currentMenu } 
          acRegresar={acRegresar} 
          onOpenItems={onOpenItems} 
          acEjecuta={onClick}
        />

        {/* { Menus.map( menu => 
            (<Link className={ classes.link } key={ menu.id } to={ menu.link } onClick={ () => onClick( menu ) }>
                <List>
                    <ListItem button>
                        <ListItemIcon> { menu.icon } </ListItemIcon>
                        <ListItemText primary={ menu.caption } />
                    </ListItem>
                </List>
            </Link>)            
        )} */}


      </Drawer>

    </div>

  );
}