import { 
    createStore, 
    combineReducers, 
    applyMiddleware, 
    compose 
} from 'redux';
import thunk from 'redux-thunk';

import { authReducer } from '../reducers/authReducer';
import { ciaReducer } from '../reducers/ciaReducer';
import { uiReducer } from '../reducers/uiReducer';
import { userReducer } from '../reducers/userReducer';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
    ui: uiReducer,
    usuario: userReducer,
    auth: authReducer,
    cia: ciaReducer,
})

export const store = createStore( 
    reducers, 
    composeEnhancers(
        applyMiddleware( thunk )
    )    
);