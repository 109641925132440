import { setError, setLoading } from "./ui";


export const getBancos = async  ( cta='' ) => {
    const url = `${ window.$baseApi }/TBancos/Bancos/${ cta }`;
    setLoading(true) ;
    try {
        const response = await fetch( url );
        const data = await response.json();
        setLoading(false) ;
        return data;
        
    } catch (err) {
        setLoading(false) ;
        setError( err );
    }
}

export const saveBanco = async ( registro ) => {
    const url = `${ window.$baseApi }/TBancos/Bancos/`;

    try {
        const response = await fetch( url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify( registro ), 
            headers:{
              'Content-Type': 'application/json'
            }
        });
        const resp = await response.json();
        if (resp.coderesult !== 200 ) {
            setError( resp.message ); 
        }
        return resp;

    } catch ( err) {
        setError( err );
    }
}

export const updateBanco = async ( registro ) => {
    const url = `${ window.$baseApi }/TBancos/Bancos/`;

    try {
        const response = await fetch( url, {
            method: 'PUT', // or 'PUT'
            body: JSON.stringify( registro ), 
            headers:{
              'Content-Type': 'application/json'
            }
        });
        const resp = await response.json();
        if (resp.coderesult !== 200 ) {
            setError( resp.message ); 
        }
        return resp;

    } catch ( err) {
        setError( err );
    }
}

export const DeleteBanco = async ( cta ) => {
    const url = `${ window.$baseApi }/TBancos/Bancos/${ cta }`;
    try {
        const response = await fetch( url, {
            method: 'DELETE', 
            // body: JSON.stringify(data), 
            headers:{
              'Content-Type': 'application/json'
            }
        });
        const resp = await response.json();
        if (resp.coderesult !== 200 ) {
            setError( resp.message ); 
        }
        return resp;
        
    } catch ( err) {
        setError( err );
    }
}

export const saveMovBan = async ( registro ) => {
    const url = `${ window.$baseApi }/TBancos/MovBan/`;
    try {
        const response = await fetch( url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(registro), 
            headers:{
              'Content-Type': 'application/json'
            }
        });
        const resp = await response.json();
        if (resp.coderesult !== 200 ) {
            setError( resp.message ); 
        }
        return resp;

    } catch ( err) {
        setError( err );
    }
}

export const saveTraspaso = async ( registro ) => {
    const url = `${ window.$baseApi }/TBancos/Traspaso/`;
    try {
        const response = await fetch( url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(registro), 
            headers:{
              'Content-Type': 'application/json'
            }
        });
        const resp = await response.json();
        if (resp.coderesult !== 200 ) {
            setError( resp.message ); 
        }
        return resp;

    } catch ( err) {
        setError( err );
    }
}

export const getEdoCtaBan = async  ( w1, w2, cta ) => {
    const url = `${ window.$baseApi }/TBancos/EdoCtaBan/${ w1 }/${ w2 }/${ cta }`;
    setLoading(true) ;
    try {
        const response = await fetch( url );
        const data = await response.json();
        setLoading(false) ;
        return data;
        
    } catch (err) {
        setLoading(false) ;
        setError( err );
    }
}

export const getRepSaldos = async  ( wDate ) => {
    const url = `${ window.$baseApi }/TBancos/RepSaldos/${ wDate }`;
    setLoading(true) ;
    try {
        const response = await fetch( url );
        const data = await response.json();
        setLoading(false) ;
        return data;
        
    } catch (err) {
        setLoading(false) ;
        setError( err );
    }
}
