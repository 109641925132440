import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import GridOnIcon from '@material-ui/icons/GridOn';

import { CustomCatalogos } from '../CustomCatalogos/CustomCatalogos';
import { setError, setLoading } from '../../actions/ui';
import { countryToFlag, showSuccess, siBorrar } from '../../Utils/Util';
import { getBancos, DeleteBanco } from '../../actions/acBancos';
import { Typography } from '@material-ui/core';
import { CapBan } from './CapBan';

// import { styles } from '../CustomCatalogos/StylesCatalogos';


// const onClickEdoCta = () => {
//     showSuccess( 'aqui estoy' );
// }

export const Bancos = () => {
    // const classes = styles();
    const columns = [

        // { name: 'edocta', label: 'EdoCta',
        // options: {filter: false, sort: false, empty: true, print: false,
        //     setCellProps: () => ( {style: {whiteSpace:'nowrap'}}),
        //     customBodyRenderLite: (dataIndex) => {
        //     return (
        //       <>
        //         <button className={ classes.acciones } onClick={() => onClickEdoCta( data[dataIndex] )}>
        //           <GridOnIcon fontSize="small"/>
        //         </button>
        //       </>
        //     );
        //   },
        //   }
        // },

        {name: 'cta', label:'Cuenta', options:{filter: false }},
        {name: 'titular', label:'Nombre', options:{filter: false, setCellProps: () => ( {style: {whiteSpace:'nowrap'}})}},
        {name: 'banco', label:'Banco', options:{setCellProps: () => ( {style: {whiteSpace:'nowrap'}})}},
        {name: 'sucursal', label:'Sucursal', options:{setCellProps: () => ( {style: {whiteSpace:'nowrap'}})}},
        {name: 'moneda', label:'Moneda', options: {
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                  <Typography variant='h5'>
                  { value !== 0 ? countryToFlag ('US') : countryToFlag ('MX')}
                  </Typography>
              );
            }
        }},
    ]

    const inicial = {
        idbanco: -1,
        iduser: -1,
        idempresa: -1,
        cta: '',
        titular: '',
        banco: '',
        sucursal: '',
        direccion: '',
        telefono: '',
        saldo: 0,
        saldocon: 0,
        moneda: 0,
        ejecutivo: '',
        alta: '',
        activo: 1,
        tipo: 'A',
    }    

    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [registro, setRegistro] = useState( inicial )
    const [alta, setAlta] = useState(null)

    const acAgregar = () => {
        setAlta(true);
        setOpen( true );
    }

    const acClose = () => {
        setRegistro( inicial );
        setOpen( false );
    }

    const onClickEdit = ( reg ) => {
        setRegistro( reg )
        setAlta(false)
        setOpen( true );
    }

    const onClickDelete = async ( reg ) => {
        const respuesta = await siBorrar();
        if ( respuesta === true ) {
            const response = await DeleteBanco( reg.cta );
            if ( response.coderesult === 200) {
                const nuevo = [];
                data.forEach( item => (
                    item.idbanco !== reg.idbanco && nuevo.push( {...item })
                ))
                showSuccess( response.Message );
                setData([ ...nuevo ])
            } else {
                setError( response.Message )
            }
        }
    }    

    useEffect(() => {
        dispatch( setLoading(true) );
        const fetchData = async() => {
           const result = await getBancos();
           setData([ ...result ]);           
           dispatch( setLoading(false) );
        };
        fetchData();
        return () => { setData([]); }
    }, []);

    useEffect(() => {
        const nuevo = [];
        if ( registro !== inicial ) {      
            data.forEach( item => (
                item.idbanco === registro.idbanco
                ? nuevo.push( {...registro} )
                : nuevo.push( {...item} ) 
            ))
            // alta && nuevo.push( {...registro} );
            setData( [...nuevo] )            
        }        
    }, [ registro ])
    
  
    return (
        <>
            { open && <CapBan open={ open } onClose={ acClose } registro={ registro } alta={ alta } setRegistro={ setRegistro }/>}
            <CustomCatalogos 
                titulo='Cuentas Bancarias'  
                campos={ columns } 
                data={ data } 
                onClickAdd={ acAgregar } 
                onClickEdit={ onClickEdit }
                onClickDelete={ onClickDelete }                
            />
        </>
    )
}
