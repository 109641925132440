import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { 
    Accordion, 
    AccordionDetails, 
    AccordionSummary, 
    createMuiTheme, 
    Fab, 
    Grid, 
    MuiThemeProvider, 
    Typography 
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import MUIDataTable from 'mui-datatables';


import { setError, setLoading } from '../../actions/ui';
import { getRepSaldos } from '../../actions/acBancos';
import { ShowError } from '../../components/ShowError/ShowError';
import { setPesos, countryToFlag } from '../../Utils/Util'
import { TextDate } from '../../components/TextDate/TextDate';
import { Waiting } from '../../components/Waiting/Waiting';
// import { styles } from './StylesReportes';
import { styles } from '../CustomReporte/StylesReportes';


const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableHeadCell: {
        root: {
        //   border: 'solid 1px #ccc',
          padding: '5px',
        }
      },
      MuiTableCell: {
        root: {
        //   border: '1px solid #ddd'
        },
      },
      MUIDataTableBodyCell: {
        root: {
          padding: '4px',
        }
      },
    }
});  

export const RepSaldos = () => {

    const classes = styles();
    const { loading } = useSelector(state => state.ui);
    const [wDate, setwDate] = useState( new Date() )

    const columns = [
        {name: 'cta', label:'Cuenta', options:{filter: false }},
        {name: 'titular', label:'Nombre', options:{filter: false, setCellProps: () => ( {style: {whiteSpace:'nowrap'}})}},
        {name: 'banco', label:'Banco', options:{setCellProps: () => ( {style: {whiteSpace:'nowrap'}})}},
        {name: 'moneda', label:'Moneda', options: {
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                  <Typography variant='h5'>
                  { value !== 0 ? countryToFlag ('US') : countryToFlag ('MX')}
                  </Typography>
              );
            }
        }},

        {name: 'saldo', label: 'Saldo', options:{ filter: false, 
            setCellProps: () => ({ align: 'right' }),
            customBodyRender: (value, tableMeta, updateValue) => setPesos( value ),
            setCellHeaderProps: () => ({ align: 'right'}),
        }},
        {name: 'saldoc', label: 'Saldo Conciliado', options:{ filter: false, 
            setCellProps: () => ({ align: 'right' }),
            customBodyRender: (value, tableMeta, updateValue) => setPesos( value ),
            setCellHeaderProps: () => ({ align: 'right'}),
        }},
    ]

    const txtEtiquetas = {
        body: {
          noMatch: "UPS, No se encontraron registros...",
          toolTip: "Ordenar",
          columnHeaderTooltip: column => `Ordenar por ${column.label}`
        },
        pagination: {
          next: "Sig Pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Registros por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtros",
        },
        filter: {
          all: "Todo",
          title: "FILTROS",
          reset: "LIMPIAR",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Show/Hide Table Columns",
        },
        selectedRows: {
          text: "row(s) selected",
          delete: "Borrar",
          deleteAria: "Delete Selected Rows",
        },        
    }


    const opciones = {
        filterType: 'dropdown',
        responsive: 'standard',
        fixedHeader: true,
        // pagination: false,
        tableBodyHeight: 'calc(100vh - 240px)',
        rowsPerPage: 50,
        rowsPerPageOptions: [10, 50, 100, 500],
        searchPlaceholder: 'Buscar...',
        selectableRows: 'none',
        draggableColumns: { enabled: true, },
        textLabels: txtEtiquetas,
        // sort: false,
        // filter: false,
    }

    const dispatch = useDispatch();
    const { msgError } = useSelector(state => state.ui);
    const [data, setData] = useState([]);
    const [ejecuta, setEjecuta] = useState( false );
    const [params, setParams] = useState({
        w1: '',
    });
    
    const showError = ( msg, tipo ) => {
        ShowError('error', msg);
        dispatch( setError(null) );
    }

    const acExecute =  () => {
        setEjecuta( false );
        if (wDate === null ) {
            dispatch( setError('La fecha es obligatoria'));
            return;
        }

        setParams({
            w1: format(wDate , 'yyyyMMdd'),
        })
        setEjecuta( true );
    }

    useEffect(() => {
        if ( ejecuta ){ 
            dispatch( setLoading(true) );
            const fetchData = async() => {
               const result = await getRepSaldos( params.w1 );
               setData([ ...result ]);           
               dispatch( setLoading(false) );
            };
            fetchData(); 
        }
            return () => { setData([]); }
    }, [dispatch, ejecuta, params.w1]);

  
    return (
        <div className={ classes.tabla }>  
            { msgError && showError( msgError, 'error') }
            <Accordion defaultExpanded >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography >Parametros</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={3}>
                            <TextDate 
                                label='Fecha' 
                                value={wDate} 
                                autoFocus 
                                onChange={ date => setwDate( date ) } 
                                required
                            />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <Fab  size="large" className={ classes.iconAdd } aria-label="execute" onClick={ acExecute } >
                                <SearchIcon />
                            </Fab> 
                        </Grid>

                    </Grid>
                </AccordionDetails>   
            </Accordion>

            { loading && <Waiting />}
            <MuiThemeProvider theme={getMuiTheme()}> 
                <MUIDataTable 
                    className={ classes.root }
                    title='Reporte de Saldos'
                    columns={ columns }
                    options={ opciones }
                    data={ data }
                />
            </MuiThemeProvider>

        </div>
    )
}
