import { types } from "../types/types";


export const setUser = ( usuario ) => {
    return ({
        type: types.userSetUsuario,
        payload: usuario 
    })
}

export const activeTab = ( idTab ) => ({
    type: types.tabsActive,
    payload: idTab
});
