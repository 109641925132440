import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import { Avatar, Box, Divider } from '@material-ui/core';
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { mainMenuOpen, uiDark, uiLight } from '../../actions/ui';
import { menuUser } from '../../data/menus';
import StylesHeader from './StylesHeader';
import { logout } from '../../actions/auth';

export const Header = () => {
  const classes = StylesHeader();
    const dispatch = useDispatch();
    const { imagen, iniciales, mail, nombre } = useSelector(state => state.usuario);

    const [AnchorEl, setAnchorEl] = React.useState(null);
    const MenuUserOpen = Boolean(AnchorEl);
    const { darkState } = useSelector(state => state.ui);
    
    const handleClickMenu = () => {
      dispatch( mainMenuOpen() );
    }

    const handleMobileMenuClose = () => {
      setAnchorEl(null);
    };
    
    const handleMobileMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleThemeChange = () => {
      if ( !darkState ) {
          dispatch( uiDark() );
      } else {
          dispatch( uiLight() );
      }
    }

    const handleLogOut = () => {
        dispatch( logout() );
    }
    
    const MenuId = 'account-menu-user';
    const MenuUser = (
        <div>
            <Menu
                anchorEl={AnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={MenuId}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={MenuUserOpen}
                onClose={handleMobileMenuClose}
            >
            <Box className={ classes.panel }>
                <Avatar className={ classes.large } alt='Imagen User'  
                // src={ imagen } 
                >
                    { iniciales }
                </Avatar>
                <Typography variant='subtitle2' color="initial" align='center' >
                    { nombre }
                </Typography> 
                <Typography variant='caption' color="initial" align='center' >
                    { mail }
                </Typography> 
            </Box>
            <Divider />

            <MenuItem onClick={ handleThemeChange }>
              <IconButton aria-label="" color="inherit">
                  { darkState ? <BrightnessHighIcon /> : <Brightness4Icon  />}
              </IconButton>
                Modo obscuro
            </MenuItem>

            { menuUser.map( item => {
                return (
                  <MenuItem key={ item.label }>
                    <IconButton key={ item.key } color="inherit" >  { item.icon } </IconButton>
                    { item.label }
                  </MenuItem>
                )
              })}

            <MenuItem onClick={ handleLogOut }>
              <IconButton aria-label="" color="inherit">
                  <ExitToAppIcon /> 
              </IconButton>
                Cerrar sesion
            </MenuItem>



            </Menu>
        </div>
    );
  
    return (
      <div className={classes.grow}>
        <AppBar position="fixed" color='primary' className={classes.barTitulo} >
          <Toolbar variant='regular' >
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={ handleClickMenu }
            >
              <MenuIcon />
            </IconButton>
            <Typography className={classes.title} variant="h6" noWrap>
                Gorostiza
            </Typography>
            
            <div className={classes.grow} />

            <IconButton
                aria-label="show user"
                aria-controls={MenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
            >
                <Avatar 
                    className={ classes.small }
                    alt='Imagen User' 
                    // src={ imagen }
                >
                  <PersonIcon />
                    {/* { iniciales } */}
                </Avatar>
            </IconButton>

          </Toolbar>
        </AppBar>
        {MenuUser}
      </div>
    );
  }
  